<template>
  <section>
    <!-- <div class="w-full bg-white rounded-md mt-2 p-4">
      <div class="w-full flex gap-2 items-center">
        <i class="pi pi-list"></i>
        <p class="text-lg font-bold">{{ route.params.id ? 'Actualizar' : 'Crear' }} Bodega</p>
      </div>
    </div> -->
    <div class="grid grid-cols-4 gap-5 mx-5">
      <div class="flex flex-col col-start-1">
        <span class="font-bold">Código Municipio</span>
        <InputText class="rounded w-full border uppercase" :disabled="idBod" type="number" v-model="model.codMpio" />
        <MessageError :text="errors.codMpio"/>
      </div>
      <div class="flex flex-col ">
        <span class="font-bold">Nombre Municipio </span>
        <InputText class="rounded w-full border uppercase" type="text" v-model="model.nomMpio" />
        <MessageError :text="errors.nomMpio"/>
      </div>
      <div class="flex flex-col">
        <span class="font-bold">Código Departamento </span>
        <InputText class="rounded w-full border" type="number" v-model="model.codDpto" />
        <MessageError :text="errors.codDpto"/>
      </div>
      <div class="flex flex-col">
        <span class="font-bold">Nombre Departamento</span>
        <InputText class="rounded w-full border uppercase" type="text" v-model="model.nomDpto" placeholder="" />
        <MessageError :text="errors.nomDpto"/>
      </div>
    </div>
    <div class="w-full flex justify-end mt-5 ">
      <Button label="Cancelar"
              class="bg-blue-900 p-button-outlined"
              @click="resetFilters"
      />
      <Button label="Guardar"
              class="bg-blue-900 mx-5 px-5"
              type="button"
              @click="onSubmit"
      />
    </div>
  </section>
</template>
<script setup>
import { ref, onMounted, defineEmits, defineProps } from 'vue'
import { useField, useForm } from 'vee-validate'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import LocalizacionService from '../../../../../services/localizacion.service'

// instancias
const _LocalizacionService = ref(new LocalizacionService())
const emit = defineEmits(['customChange'])
const props = defineProps({
  idLoc: String
})
// referencias

const validationSchema = yup.object({
  codMpio: yup.string().required('El código es requerido').label(''),
  nomMpio: yup.string().required('El nombre es requerido').label(''),
  codDpto: yup.string().required('El código es requerido').label(''),
  nomDpto: yup.string().required('El nombre es requerido').label('')
})

const { errors, handleSubmit, values: model, handleReset } = useForm({ validationSchema })

  useField('codMpio', null, { initialValue: '' })
  useField('nomMpio', null, { initialValue: '' })
  useField('codDpto', null, { initialValue: '' })
  useField('nomDpto', null, { initialValue: '' })

const onSubmit = handleSubmit(async (values) => {
    if (!values) throw new Error('Error de validación')
    for (const [index, item] of Object.entries(model)) {
      if (typeof item === 'string') {
        model[index] = item.toUpperCase()
      }
    }
    if (props.idLoc) {
      _LocalizacionService.value.editarLoc(model).then((res) => {
        handleReset()
        Swal.fire({
          icon: 'success',
          title: 'Localización Actualizada',
          text: 'La Localización se ha actualizado correctamente'
        }).then(() => {
          emit('crearLoc')
        })
      })
    } else {
      _LocalizacionService.value.crearLoc(model).then((res) => {
        handleReset()
        Swal.fire({
          icon: 'success',
          title: 'Localización creada',
          text: 'La Localización se ha creado correctamente'
        }).then(() => {
          emit('crearLoc')
        })
      })
    }
})
const resetFilters = () => {
  model.value = {
    codMpio: '',
    nomMpio: '',
    codDpto: '',
    nomDpto: ''
  }
}

onMounted(() => {
  if (props.idLoc) {
    _LocalizacionService.value.getLocalizacion(props.idLoc).then(async ({ data }) => {
      for (const [index, item] of Object.entries(data)) {
        model[index] = item
      }
    })
  }
})
</script>
<style>
</style>
